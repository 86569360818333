/*jshint esversion: 6 */

let drive_index = 1050;
let drive_initialized = false;
let drive_running = false;

let drive_map = null;
let drive_timer = null;
let run_index = 200;
let run_initialized = false;
let run_running = false;
const run_path = [];
let run_map = null;
let run_timer = null;
let hike_index = 680;
let hike_initialized = false;
let hike_running = false;
const hike_path = [];
let hike_map = null;
let hike_timer = null;

maptilersdk.config.apiKey = token;
maptilersdk.config.session = false;

// if (env !== 'development') {
//   Sentry.init({
//     dsn: 'https://4842793613204c38af323ab6e6260354@sentry.io/1275180',
//     release: release,
//     environment: env,
//     integrations: [new Sentry.Integrations.BrowserTracing()]
//   });
// }

this.bluelane = function() {
  drive_map = initializeMap(drive_points, drive_index, 13, 'drive', 'map-drive', 1000);
  run_map = initializeMap(run_points, run_index, 17, 'run', 'map-run', 1000);
  hike_map = initializeMap(hike_points, hike_index, 14, 'hike', 'map-hike', 2000);
};

function initializeMap(points, index, zoom, maptype, divid, timer_interval) {
  let i, position_cord;
  let asc, end;
  const path = [];
  let running = false;
  let timer = null;

  // Drive map
  const current_position = points[index];
  for (i = 0, end = index+1, asc = 0 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
    position_cord = [points[i][1], points[i][0]];
    path.push(position_cord);
  }
  index++;

  let default_style;

  switch (maptype) {
    case "drive": default_style = maptilersdk.MapStyle.STREETS; break;
    case "run": default_style = maptilersdk.MapStyle.HYBRID; break;
    case "hike": default_style = maptilersdk.MapStyle.OUTDOOR; break;
  }

  // console.log current_position
  const centerCoord = new maptilersdk.LngLat(current_position[1], current_position[0]);

  let map = new maptilersdk.Map({
    container: divid,
    style: default_style,
    zoom: zoom,
    center: centerCoord,
    navigationControl: false,
    geolocateControl: false,
    maptilerLogo: false,
    attributionControl: false
  });

  map.on('style.load', function() {
    const geo_json_data = {
      "type": "FeatureCollection",
      "features": [
          {
              "type": "Feature",
              "geometry": {
                  "type": "LineString",
                  "coordinates": path
              }
          }
      ]
    };

    map.addSource('path', { type: 'geojson', data: geo_json_data });

    map.addLayer({
      'id': 'route',
      'type': 'line',
      'source': 'path',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': '#4099ff',
        'line-width': 8
      }
    });
  
    let element = document.createElement('div');
    element.className = 'location_marker_blue';
    let location_marker = new maptilersdk.Marker({element: element}).setLngLat([centerCoord.lng, centerCoord.lat]).addTo(map);
    const div = '#' + divid;

    let appear_options = { force_process: true };
    $.appear(div, appear_options);
    $(div).on('appear', function(event, $all_appeared_elements) {
      if (!running) {
        running = true;
        timer = setInterval((function() {
          if (index < points.length) {
            const coord = [points[index][1], points[index][0]];
            path.push(coord);

            // Update line path
            const geo_json_data = {
              "type": "FeatureCollection",
              "features": [
                  {
                      "type": "Feature",
                      "geometry": {
                          "type": "LineString",
                          "coordinates": path
                      }
                  }
              ]
            };

            map.getSource('path').setData(geo_json_data);
            location_marker.setLngLat(coord);
            map.panTo(coord);
            index++;
          }
        }), timer_interval);
      }
    });
    $(div).on('disappear', function(event, $all_disappeared_elements) {
      if (running) {
        clearTimeout(timer);
        running = false;
      }
    });
  });

  return map;
}

$(document).ready(function() { bluelane(); });